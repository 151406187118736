.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(55, 55, 55, 0.5);
    margin-left: -220px;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

@media only screen and (max-width: 950px) {
    .modalBackground {
        margin-left: 0px;
    }
}

/* width */
.modalBackground ::-webkit-scrollbar {
    width: 1px;
    display: none;
}

/* Track */
.modalBackground ::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.modalBackground ::-webkit-scrollbar-thumb {
    background: transparent;
}

.modalContainer {
    min-width: 500px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    z-index: 11;
    overflow: auto;
}

.modalOverlay {
    position: fixed;
    overflow: auto;
    scrollbar-width: none;
    /* height: calc(100% - 52px);
    top: 50px; */
    height: calc(100% - 4px);
    top: 2px;
}

@media only screen and (max-width: 950px) {
    .modalContainer {
        min-width: 200px;
    }

    .modalOverlay {
        min-width: 200px;
    }
}