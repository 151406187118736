.App {
  text-align: center;
}

.textButton {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  color: rgb(105, 102, 80);
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}

@media only screen and (max-width: 1070px) {
  .textButton {
    font-size: 12px;
    line-height: 24px;
  }

}
.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: rgb(105, 102, 80);
  margin-left: 0.25rem;
}

.invalid {
  color: firebrick;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: rgb(197, 196, 187);
  color: firebrick;
  font-size: 20px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.instructions-wrap {
  position: relative;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  color: grey;
  padding: 0.25rem;
  position: absolute;
  bottom: 65px;
  background: lightgoldenrodyellow;
  border: 2px solid grey;
  z-index: 1;
}

@media only screen and (max-width: 991px) {
  .instructions {
    font-size: 14px;
    line-height: 16px;
    bottom: 55px;
  }
}

.instructions>svg {
  margin-right: 0.25rem;
}

