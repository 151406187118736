@import url('https://fonts.googleapis.com/css?family=Playpen+Sans');
@import url('https://fonts.googleapis.com/css?family=Acme');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Playpen Sans'; 
    background-color: rgba(197, 214, 46, 0.1);   
    /* font-family: 'Acme'; */
    /* color: rgba(255, 255, 255, 0.748);  */
}

body {
    /* margin-left: 220px; */
    height: 100%;
    width:100%;
}

.mainbg {
    width: 100%;
    min-height: 100vh;
}

.image_bg {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-image: url("assets/images/smic-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


.main-content {
    padding: 30px 30px 30px 30px;
    display:flex;
    flex-direction: column;
}

.wrap-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.column-content {
    display: flex;
    flex-direction: column;
    width:100%;
}

@media only screen and (max-width: 950px) {
    body {
        margin-left: 0px;
    }
  .main-content {
      padding: 10px 10px 10px 10px;
  }
}

.headline {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    font-size: 25px;
    background-color: inherit;
}

.headlineDark {
    width: 100%;
    margin-top: 25px;
    text-align: center;
    font-size: 25px;
    background-color: inherit;
}

.headline-big {
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 38px;
    font-weight: 600;
}

.headline-normal {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 16px;
    background-color: inherit;
}

.headline-small {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 11px;
    background-color: inherit;
}

.whiteBox {
    max-width: 800px;
    min-width: 320px;
    margin-top: 10px;
    border: 2px solid white;
    border-radius: 5px;
    background-color: white;
    align-self: center;
    box-shadow: 6px 6px 2px 1px rgba(0, 0, 255, .2);

}

.colorBox {
    max-width: 800px;
    min-width: 320px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding:15px;
    border: 2px solid black;
    border-radius: 5px;
    background-color: rgb(197, 214, 46);;
    align-self: center;
}